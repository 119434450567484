import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Container from '../components/Container';
import Seo from '../components/Seo';
import config from '../config';
import { createArticleDate, useHasMounted } from '../helpers';
import { typography } from '../styles/typography';

const Content = styled.div`
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
    width: 100%;

    > h1 {
      ${typography.h2};
    }

    > ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
`;

const ArticleLink = styled(Link)`
  display: block;
  width: 100%;
  background-color: white;
  margin: ${config.sizes.spacing / 2}px 0;
  padding: ${config.sizes.spacing / 3}px ${config.sizes.spacing / 2}px;
  text-decoration: inherit;
  color: inherit;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;

  h3 {
    ${typography.h6};
    margin: 0;
    margin-bottom: ${config.sizes.spacing / 3}px;
    line-height: unset;
  }

  p {
    ${typography.p};
    margin: 0;
    line-height: unset;
  }

  span {
    ${typography.span};
    font-size: 0.7em;
  }
`;

function ArticlesPage({ data }) {
  const mdxArticles = data.allMdx.nodes;
  const hasMounted = useHasMounted();

  return (
    <>
      <Seo title="Articles" description="My thoughts, guides, and more." />
      <Content>
        <Container verticalPadding={false}>
          <h1>Latest Articles</h1>
          {mdxArticles.map((mdxArticle) => (
            <motion.div whileHover={{ scale: 1.05 }} key={mdxArticle.id}>
              <ArticleLink to={mdxArticle.frontmatter.slug}>
                <h3>{mdxArticle.frontmatter.title}</h3>
                <p>{mdxArticle.frontmatter.subheading}</p>
                {hasMounted && (
                  <span>{createArticleDate(mdxArticle.frontmatter.date)}</span>
                )}
              </ArticleLink>
            </motion.div>
          ))}
        </Container>
      </Content>
    </>
  );
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        id
        frontmatter {
          slug
          title
          date
          subheading
        }
      }
    }
  }
`;

export default ArticlesPage;
